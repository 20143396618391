import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:token",
      name: "Sign Up",
      component: () => import("@/pages/SignUp.vue"),
      props: true,
    },
    // {
    //   path: "/",
    //   name: "Sign Up",
    //   component: () => import("@/pages/SignUp.vue"),
    // },
    {
      path: "/sign-in",
      name: "Sign in",
      component: () => import("@/pages/SignIn.vue"),
    },
    {
      path: "/rewards",
      name: "Rewards",
      component: () => import("@/pages/Rewards.vue"),
    },
    {
      path: "/profile",
      name: "Profile",
      component: () => import("@/pages/Profile.vue"),
    },
  ],
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;

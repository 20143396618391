import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { store } from './store'
import VueAxios from "vue-axios";
import axios from "axios";
import './assets/style/style.scss'
import VueMask from '@devindex/vue-mask';
import ScrollParallax from 'vue3-parallax/src/components/ScrollParallax.vue';

const app = createApp(App)

app.use(router)
app.use(store)
app.use(VueMask);
app.use(VueAxios, axios);

app.provide('axios', app.config.globalProperties.axios)
app.component('scroll-parallax', ScrollParallax);

app.mount('#app')




import axios from "axios";

const baseUrl =
  "https://api.inscreenlab.com:3000/api/development/v1";
  


export class Service {
  static async signUp(id, data) {
    return axios
      .post(
        `${baseUrl}/user-auth/register?token=${id}`, 
        data
      )
      .then((response) => {
        console.log(response.data, 'response');
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }

  static async signIn(data) {
    return axios
      .post(
        `${baseUrl}/user-auth/login`, 
        data
      )
      .then((response) => {
        console.log(response.data, 'response');
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }

  static async logout() {
    return axios
      .post(
        `${baseUrl}/user-auth/logout`,
      )
      .then((response) => {
        console.log(response.data, 'response');
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }

  static async getAllPrizes() {
    return axios
      .get(
        `${baseUrl}/prize`
      )
      .then((response) => {
        console.log(response.data, 'response');
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }

  static async getUserPrizes(id) {
    return axios
      .get(
        `${baseUrl}/prize/userId/${id}`
      )
      .then((response) => {
        console.log(response.data, 'response');
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }

  static async getPrizeById(id) {
    return axios
      .get(
        `${baseUrl}/prize/${id}`
      )
      .then((response) => {
        console.log(response.data, 'response');
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }

  static async getUserPoints(id) {
    return axios
      .get(
        `${baseUrl}/user/points/${id}`
      )
      .then((response) => {
        console.log(response.data, 'response');
        return response.data;
      })
      .catch(() => {
        return false;
      });
  }

}

<template>
  <div class="app">
    <header>
      <div
        class="btn-back"
        @click="goBack()"
        :class="{ hidden: router.currentRoute.value.name == 'Sign up' }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="18"
          viewBox="0 0 9 18"
          fill="none"
        >
          <path
            d="M0.422263 10.1127C-0.140754 9.49819 -0.140754 8.50182 0.422263 7.88727L6.53887 1.21091C7.10189 0.596364 8.01472 0.596364 8.57774 1.21091C9.14075 1.82545 9.14075 2.82182 8.57774 3.43636L2.46113 10.1127C1.89811 10.7273 0.985281 10.7273 0.422263 10.1127Z"
            fill="#CFCFCF"
          />
          <path
            d="M8.57774 16.7891C8.01472 17.4036 7.10189 17.4036 6.53887 16.7891L0.422263 10.1127C-0.140754 9.49819 -0.140754 8.50182 0.422263 7.88727C0.985281 7.27273 1.89811 7.27273 2.46113 7.88727L8.57774 14.5636C9.14075 15.1782 9.14075 16.1746 8.57774 16.7891Z"
            fill="#CFCFCF"
          />
        </svg>
      </div>
      <div v-if="router.currentRoute.value.name == 'Profile'" class="title">
        <h2>profile</h2>
        <h1>
          {{ router.currentRoute.value.name }}
        </h1>
      </div>
      <h1 v-else>
        {{ router.currentRoute.value.name }}
      </h1>
    </header>
    <router-view :key="$route.fullPath" />
    <div
      v-if="router.currentRoute.value.name == 'Profile'"
      class="logout"
      @click="logout"
    >
      Log out
    </div>
  </div>
  <!-- <Bar id="my-chart-id" :options="chartOptions" :data="chartData" /> -->
</template>

<script>
import { useRouter } from "vue-router";
import { Service } from "@/service";

// import { Bar } from "vue-chartjs";
// import {
//   Chart as ChartJS,
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale,
// } from "chart.js";

// ChartJS.register(
//   Title,
//   Tooltip,
//   Legend,
//   BarElement,
//   CategoryScale,
//   LinearScale
// );

export default {
  name: "App",
  components: {
    // Bar,
  },
  setup() {
    const router = useRouter();

    function goBack() {
      router.go(-1);
    }

    function logout() {
      Service.logout();

      setTimeout(() => {
        router.push({
          path: "/sign-in",
        });
      }, 2000);
    }

    return { router, goBack, logout };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
